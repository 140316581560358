import styled, { css } from 'styled-components'
import { fontFamily, invisibleSvgBgColor } from 'common/constants'
import { media } from 'components/presentational'
import { RouterLink } from 'components/presentational/link'
import { SocialNote } from 'components/page/Boarding/SocialNote/SocialNote'
import { Footer } from 'components/block/Footer'
import { TooltipDirection } from 'components/landing/MambaFeature/MambaFeature.constants'

export const StyledTitle = styled.h1`
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${invisibleSvgBgColor};
  font-family: ${fontFamily};
  margin-bottom: 40px;
  text-align: center;

  @media (min-width: 375px) {
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    text-align: left;
    margin-bottom: 40px;
  }
`

export const StyledEnvelopeSvgWrapper = styled.div`
  ${(props) => props.theme.paddingRight}: 11px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${media.phone`
    padding: 10px 20px 20px 20px;
    overflow: visible;
  `};

  @media (min-width: 601px) {
    width: 335px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1024px) {
    width: auto;
  }
`

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    margin-bottom: 10px;
  }

  @media (min-width: 1024px) {
    width: 335px;
    justify-content: space-between;
  }
`

export const Body = styled.main`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;

  ${media.phone`
    flex-direction: column;
  `};

  @media (min-width: 1024px) {
    width: 335px;
  }
`

export const RegisterWrapper = styled.div`
  margin-bottom: 10px;
`

export const ActionBlock = styled.div`
  @media (min-width: 375px) {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`

export const AppLink = styled.div`
  margin-bottom: 10px;
  width: 100%;
`

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`

export const LinkWrapper = styled.div`
  margin: 0 10px;
`

export const ButtonGroupList = styled.div`
  display: flex;
`

const ButtonRegistrationWrapperCss = css`
  flex-grow: 1;
  width: 50%;
`

export const ButtonRegistrationOtherWaysWrapper = styled.div`
  ${ButtonRegistrationWrapperCss};
  ${(props) => props.theme.marginRight}: 10px;
  position: relative;
`

export const ButtonLoginWrapper = styled.div`
  ${ButtonRegistrationWrapperCss};
  position: relative;
`

export const RegistrationTooltipWrapper = styled.div<{
  directionTooltip: TooltipDirection
}>`
  position: absolute;
  ${(props) =>
    props.directionTooltip === TooltipDirection.right
      ? props.theme.left
      : props.theme.right}: 0;
  bottom: 59px;
`

export const LangSelectWrapper = styled.div`
  display: none;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-top: 40px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    display: flex;
    min-width: 1024px;
    padding-left: 70px;
    padding-right: 70px;
    box-sizing: border-box;
  }

  @media (min-width: 1440px) {
    width: 1120px;
  }
`

export const MambaFeaturePicture = styled.img`
  position: absolute;
  bottom: 0;
  ${(props) => props.theme.left}: 0;
  ${(props) => props.theme.right}: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  transform: scaleX(${(props) => props.theme.scaleX});

  @media (min-width: 1024px) {
    ${(props) => props.theme.right}: auto;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }
`

export const MambaFeatureContent = styled.div`
  display: flex;
  flex-grow: 1;

  align-items: center;

  @media (min-width: 1024px) {
    align-items: normal;
  }
`

export const MambaFeatureFirst = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.paddingLeft}: 0;
  flex-grow: 1;
  justify-content: center;

  @media (min-width: 1024px) {
    ${(props) => props.theme.paddingLeft}: 40px;
    padding-bottom: 40px;
  }
`

export const MambaFeatureSecond = styled.div`
  position: relative;
  width: 100%;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`

export const MambaFeatureLogoLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  padding-top: 10px;

  @media (min-width: 1024px) {
    padding-top: 0;
  }
`

export const MambaFeatureSocialNote = styled(SocialNote)`
  text-align: center;
  font-size: 11px;
  line-height: 14px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  @media (min-width: 1024px) {
    text-align: left;
  }
`

export const FormattedMessageMobile = styled.span`
  display: none;
  @media (max-width: 374px) {
    display: block;
  }
`

export const FormattedMessageTabletDesktop = styled.span`
  display: none;

  @media (min-width: 375px) {
    display: block;
  }
`

export const MambaFeaturePictureTouchWrapper = styled.div`
  display: none;

  @media (min-width: 375px) {
    position: relative;
    display: flex;
    height: 250px;
    overflow: hidden;
    width: 335px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 768px) {
    min-height: 250px;
    overflow: visible;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

export const MambaFeatureAppIcon = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }
`

export const MambaFeatureFooterWrapper = styled(Footer)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media (min-width: 1024px) {
    text-align: left;
    align-items: flex-start;
  }
`

export const StyledFlagBoarding = styled.div<{
  flagUrl: string
}>`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.flagUrl});
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: auto 100%;
  ${(props) => props.theme.marginRight}: 6px;
  flex-shrink: 0;
`
