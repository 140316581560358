import styled, { css } from 'styled-components'
import { media } from 'components/presentational'
interface ModalTitleProps {
  $size?: number
}
export const ModalTitleCss = css<ModalTitleProps>`
  font-size: ${(props) => props.$size}px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e3d49;
  font-weight: bold;
  text-align: center;
`
export const StyledModalTitle = styled.h2<ModalTitleProps>`
  ${media.phone`
    margin-top: 60px;
  `}
  ${ModalTitleCss};
`

export const AstroStarTitle = styled.h2<ModalTitleProps>`
  ${ModalTitleCss};
  margin: 0 0 10px 0;
`

export const AstroStarTitleAgreement = styled.h2<ModalTitleProps>`
  ${ModalTitleCss};
  margin: 0 0 15px 0;
`

StyledModalTitle.defaultProps = {
  $size: 21,
}
