import { isLoveMailru } from 'common/constants'
import { FormattedMessage } from 'react-intl'
import { PlainLink } from 'components/presentational/link'
import React from 'react'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

export const Copyright = () => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )
  if (isLoveMailru(partnerId)) {
    return (
      <FormattedMessage
        id={'boarding.copyright_mail'}
        values={{
          currentYear: new Date().getFullYear(),
          link: (
            <PlainLink href={'https://mail.ru'}>
              <FormattedMessage id={'boarding.copyright_mail.app'} />
            </PlainLink>
          ),
        }}
      />
    )
  }

  return (
    <FormattedMessage
      id="boarding.copyright_new"
      defaultMessage="© «Mamba» — Единая Служба Знакомств и Общения 2002 — {currentYear}"
      values={{ currentYear: new Date().getFullYear(), br: <br /> }}
    />
  )
}
