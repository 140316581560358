import loadable from '@loadable/component'
import { defaultLoadableProps } from 'components/system/loadable/defaultLoadableProps'

export const PromoCodeDetailsLoadable = loadable(
  () => import('./PromoCodeDetails'),
  {
    fallback: (({ error }) => {
      if (error) {
        console.error('PromoCodeAcceptedBlock code split error', error)
      }
      return undefined
    })(defaultLoadableProps),
  }
)
