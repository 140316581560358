import styled, { css } from 'styled-components'
import {
  isLoveMailru,
  isMamba,
  isWamba,
  isYonja,
  smallFontSize,
} from 'common/constants'
import { PlainLink, RouterLink } from '../../presentational/link'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { media } from '../../presentational'
import { findCorporateSiteLocale } from 'functions/index'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { mambaCorporateSite } from 'components/page/Settings/Settings.constants'
import { aboutCompanyPath } from 'components/page/Boarding/boarding.paths'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { LinkWrapper } from 'components/block/Footer/LinkWrapper'
import { Colors } from 'common-constants/colors'

export const FooterLinkLike = css`
  ${media.phone`
    margin-bottom: 10px;
  `};
`

export const FooterLink = styled(RouterLink)`
  ${FooterLinkLike};
`

export const SupportFooterLink = styled(FooterLink)`
  margin: 0 20px;
`

export const LangWrapper = styled.div`
  ${FooterLinkLike};
`

const Copyright = styled.div`
  ${media.phone`
    margin-bottom: 20px;
  `};
`

const CopyrightMamba = styled.div`
  display: none;
  @media (min-width: 768px) {
    margin-bottom: 20px;
    display: block;
  }
`

const FooterContainer = styled.div`
  font-family: sans-serif;
  font-size: ${smallFontSize}px;
  color: ${Colors.noteColor};
`

const Navigation = styled.nav`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.tablet`
    flex-direction: column;
  `};
`

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  ${media.tablet`
    flex-wrap: wrap;
  `};
`

export const BottomGroup = styled(Group)`
  ${media.tablet`
    border-top: solid 1px #f2f2f2;
    padding-top: 10px;
  `};
  ${media.phone`
    padding-top: 20px;
  `};
`

/**
 * @deprecated see Copyright.tsx
 * @returns {JSX.Element}
 * @constructor
 */
export const SwitchCopyright = () => {
  const currentYear = new Date().getFullYear()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  const copyrightElement = (
    <FormattedMessage
      id={'boarding.copyright'}
      defaultMessage={
        '© «Mamba» — Единая Служба Знакомств и Общения;{br}2002—{currentYear}'
      }
      values={{ currentYear: currentYear, br: <br /> }}
    />
  )

  if (isMamba(partnerId)) {
    return <CopyrightMamba>{copyrightElement}</CopyrightMamba>
  }

  if (isLoveMailru(partnerId)) {
    return (
      <Copyright>
        <FormattedMessage
          id={'boarding.copyright_mail'}
          defaultMessage={'© 1999—{currentYear} {link}'}
          values={{
            currentYear: currentYear,
            link: <PlainLink href={'https://mail.ru'}>{'Mail.Ru'}</PlainLink>,
          }}
        />
      </Copyright>
    )
  }

  return <Copyright>{copyrightElement}</Copyright>
}

export const MailruSalesLink = () => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  if (isLoveMailru(partnerId)) {
    return (
      <LinkWrapper>
        <PlainLink
          href={'https://sales.mail.ru'}
          data-name={'adv-mailru-action'}
        >
          <FormattedMessage
            id={'boarding.footer.mailru.adv'}
            defaultMessage={'Реклама на Mail.ru'}
          />
        </PlainLink>
      </LinkWrapper>
    )
  }

  return null
}

export const LinkCompany = () => {
  const { locale, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { locale, partnerId } }) => ({
      locale,
      partnerId,
    })
  )

  const { baseUrl } = useLayoutContext()

  if (isMamba(partnerId)) {
    return (
      <LinkWrapper>
        <RouterLink
          to={mergeAllUrls(baseUrl, aboutCompanyPath)}
          data-name={'corp-site-action'}
        >
          <FormattedMessage
            id={'boarding.footer.about'}
            defaultMessage={'О компании'}
          />
        </RouterLink>
      </LinkWrapper>
    )
  }

  if (isLoveMailru(partnerId)) {
    return (
      <LinkWrapper>
        <PlainLink
          href={'https://corp.mail.ru/'}
          data-name={'footer-corp-site-action'}
          target={'_blank'}
        >
          <FormattedMessage
            id={'boarding.footer.about'}
            defaultMessage={'О компании'}
          />
        </PlainLink>
      </LinkWrapper>
    )
  }

  if (isYonja(partnerId) || isWamba(partnerId)) {
    const corporateSiteLocale = findCorporateSiteLocale(locale)
    return (
      <LinkWrapper>
        <PlainLink
          href={`${mambaCorporateSite}/${corporateSiteLocale}`}
          data-name={'footer-corp-site-action'}
          target={'_blank'}
        >
          <FormattedMessage
            id={'boarding.footer.about'}
            defaultMessage={'О компании'}
          />
        </PlainLink>
      </LinkWrapper>
    )
  }

  return null
}

export const Footer = ({ className, children }) => (
  <FooterContainer className={className}>
    <SwitchCopyright />
    <Navigation>{children}</Navigation>
  </FooterContainer>
)
