export enum NavigationPlace {
  search = 'search',
  rating = 'rating',
  feed = 'feed',
  blog = 'blog',
}

/**
 * Отступ, который ломал высоту чата.
 * В итоге происходила ошибка:
 * https://youtrack.mamba.ru/issue/M-5951
 */
export const mailNavMarginBottom = 16

export const blogPath = '/blog/' as const
