import React, { FC } from 'react'
import { SpaceGrow } from 'components/presentational/separator'
import { FormAction } from 'components/presentational/form'
import { OrdinaryButton } from 'components/presentational/button'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
  next: {
    id: 'form.button.further',
    defaultMessage: 'Далее',
  },
})

export interface SubmitGroupProps {
  disabled?: boolean
  loading?: boolean
}

export const SubmitGroup: FC<SubmitGroupProps> = ({
  disabled = false,
  loading,
}) => {
  const intl = useIntl()

  return (
    <>
      <SpaceGrow />
      <FormAction>
        <OrdinaryButton
          type={'submit'}
          value={intl.formatMessage(messages.next)}
          disabled={disabled}
          loading={loading}
        />
      </FormAction>
    </>
  )
}
