import styled from 'styled-components'
import { media } from 'components/presentational'

export const NotPhone = styled.div`
  display: flex;
  flex-direction: column;
  ${media.phone` display: none; `}
`

export const Phone = styled.div`
  display: none;
  flex-direction: column;
  ${media.phone` display: flex; `}
`
