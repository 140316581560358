import React, { SVGProps } from 'react'

export const ArrowBackSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.45836 5.95502C8.97471 6.53034 8.9414 7.42874 8.38396 7.96166L5.16726 11.0368C11.4017 9.9461 17.8124 11.1186 23.3065 14.3588C23.9663 14.7479 24.1955 15.6153 23.8185 16.2961C23.4415 16.977 22.6011 17.2136 21.9414 16.8245C17.1891 14.0218 11.6744 12.9392 6.27155 13.7331L8.93323 15.9811C9.52126 16.4778 9.60785 17.3724 9.12664 17.9793C8.64542 18.5862 7.77862 18.6756 7.19059 18.1789L0 12.1057L6.5141 5.87824C7.07153 5.34533 7.94201 5.3797 8.45836 5.95502Z"
      fill="#232E37"
    />
  </svg>
)
