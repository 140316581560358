import { AbTestPromoCodeReg } from 'reducers/system/abTestReducer.constants'

/** Определяет, показывать ли страницу /code-accepted */
export const isShowPromoCodeAcceptedPage = (abTest?: AbTestPromoCodeReg) => {
  if (!abTest) {
    return
  }

  return (
    abTest === AbTestPromoCodeReg.GroupG2 ||
    abTest === AbTestPromoCodeReg.GroupG3
  )
}

/** Определяет, показывать ли блок с информацией о промокоде */
export const isShowPromoCodeDetails = (abTest?: AbTestPromoCodeReg | null) => {
  if (!abTest) {
    return
  }

  return (
    abTest === AbTestPromoCodeReg.GroupG1 ||
    abTest === AbTestPromoCodeReg.GroupG3
  )
}
