import styled from 'styled-components'
import { media } from 'components/presentational'

export const PhoneOnly = styled.div`
  display: none;
  ${media.phone`
    display: flex;
    flex-direction: column;
  `}
`
