export const tooltipRegistrationWithLoginId = 'tooltipRegistrationWithLoginId'
export const tooltipRegistrationId = 'tooltipRegistrationId'

export enum TooltipDirection {
  right = 'right',
  left = 'left',
}

export const photos: Record<number, Function> = {
  0: require.context(
    './MambaFeatureImage/first',
    false,
    /^\.\/.*\.(jpg|webp|avif)$/
  ),
  1: require.context(
    './MambaFeatureImage/second',
    false,
    /^\.\/.*\.(jpg|webp|avif)$/
  ),
  2: require.context(
    './MambaFeatureImage/third',
    false,
    /^\.\/.*\.(jpg|webp|avif)$/
  ),
  3: require.context(
    './MambaFeatureImage/fourth',
    false,
    /^\.\/.*\.(jpg|webp|avif)$/
  ),
}
