import { Content, ViewContent } from 'components/presentational/modal'
import ModalNavigation from 'components/presentational/ModalNavigation'
import { BackSvg } from 'components/presentational/svg/BackSvg'
import React, { FC, ReactElement, ReactNode } from 'react'

export const DefaultModalContent: FC<{
  icon?: ReactElement
  historyBack?: boolean
  onBackClick?: () => void
  children: ReactNode
}> = ({ icon = <BackSvg />, historyBack = true, onBackClick, children }) => {
  return (
    <Content>
      <ModalNavigation
        historyBack={historyBack}
        onBackClick={onBackClick}
        icon={icon}
        forceHideDummy={true}
      />
      <ViewContent>{children}</ViewContent>
    </Content>
  )
}
