import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { DEFAULT_LOCALE, isMamba } from 'common/constants'

export const useShowCitiesLink = () => {
  const { locale, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { locale, partnerId } }) => ({
      locale,
      partnerId,
    })
  )
  const showFooterLinks = isMamba(partnerId)

  return locale === DEFAULT_LOCALE && showFooterLinks
}

export const useShowCountries = () => {
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  return isMamba(partnerId)
}
